<template>
  <form
    id="microblading-info"
    action
    data-vv-scope="microblading-info-form"
    @submit.prevent="validateForm('microblading-info-form')"
  >
    <v-row>
      <v-col cols="12">
        <ServiceSelector />
      </v-col>
      <v-col cols="12" md="7">
        <body-selector
          id="bodySelector"
          ref="body_selector"
          type="laser"
          :title="$t('select', { name: $t('appointments.body_part_laser') })"
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="6">
            <label class="text-no-wrap">{{ $t("n_customers") }}</label>
            <v-select
              :items="range()(1, 5)"
              outlined
              dense
              hide-details
              v-model="n_customers"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <label>{{ $t("appointments.description") }}</label>
            <v-textarea
              outlined
              v-model="customer.description"
              auto-grow
              rows="1"
              hide-details
              style="border-radius: 10px"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        v-if="!$route.params.tattooer_id || $route.params.pre"
        @click="
          $route.params.pre && $route.params.tattooer_id
            ? $router.push({
                name: 'customer_inscription_studio_pre',
                params: {
                  token: $route.params.token,
                  back: true,
                },
              })
            : $emit('back')
        "
        style="height: 30px; width: 115px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "LaserInfo",
  data() {
    return {};
  },
  mounted() {
    this.fetchSubservice();
    setTimeout(() => {
      this.fetchBoxes();
    }, 1000);
  },
  components: {
    BodySelector: () => import("@/components/ui/BodySelector"),
    ServiceSelector: () => import("@/components/inscription/ServiceSelector"),
  },
  methods: {
    select(part) {
      this.customer.sub_type = part.description;
      this.customer.sub_type_id = part.id;
      this.customer.body_part = null;
      this.customer.inner_part = null;
      this.customer.inner_part_other = null;
    },
    ...mapGetters("app", ["range", "formatTime"]),
    ...mapGetters("inscriptions", ["servicePrice"]),
    async validateForm(scope) {
      //this.error.clear();
      let body_val = await this.$refs.body_selector.validateForm("body-form");

      this.$validator.validateAll(scope).then((result) => {
        if (result && body_val) {
          this.$emit("validated", this.studio);
        }
      });
    },
    ...mapActions("subservices", ["getSubServices"]),
    fetchSubservice() {
      this.getSubServices({
        filters: { studio_id: this.customer.studio.id, marketplace: true },
        pagination: { itemPerPage: -1 },
        service_id: 4,
      }).then((response) => {
        this.sub_services = response.data;
      });
    },
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      if (this.$store.getters["auth/isLoggedIn"])
        this.getBoxes({
          pagination: this.options,
          filters: { ...this.filters, studio_id: this.customer.studio.id },
        }).then((boxes) => {
          this.boxes = boxes.data;
        });
    },
  },
  computed: {
    ...mapState("inscriptions", [
      "tattooerState",
      "customerState",
      "subServicesState",
    ]),
    sub_services: {
      get() {
        return this.subServicesState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_SUBSERVICES", value);
      },
    },
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },

    n_customers: {
      get() {
        return this.customer.extra_customers.length + 1;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_EXTRA_CUSTOMERS", value - 1);
      },
    },
    boxes_obligatoris() {
      if (this.$store.getters["auth/isLoggedIn"]) {
        /*let s = this.customer.studio.user.setting.find(
          (x) => x.key === "box_active"
        );
        if (s && s.value === "1") {
          let s2 = this.customer.studio.user.setting.find(
            (x) => x.key === "boxes_obligatoris"
          );
          if (s2) {
            return s2.value === "1";
          }
        }*/
      }
      return false;
    },
  },
};
</script>