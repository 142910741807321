var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"id":"microblading-info","action":"","data-vv-scope":"microblading-info-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('microblading-info-form')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ServiceSelector')],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('body-selector',{ref:"body_selector",attrs:{"id":"bodySelector","type":"laser","title":_vm.$t('select', { name: _vm.$t('appointments.body_part_laser') })}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t("n_customers")))]),_c('v-select',{attrs:{"items":_vm.range()(1, 5),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.n_customers),callback:function ($$v) {_vm.n_customers=$$v},expression:"n_customers"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("appointments.description")))]),_c('v-textarea',{staticStyle:{"border-radius":"10px"},attrs:{"outlined":"","auto-grow":"","rows":"1","hide-details":""},model:{value:(_vm.customer.description),callback:function ($$v) {_vm.$set(_vm.customer, "description", $$v)},expression:"customer.description"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-3 text-right"},[(!_vm.$route.params.tattooer_id || _vm.$route.params.pre)?_c('v-btn',{staticStyle:{"height":"30px","width":"115px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.$route.params.pre && _vm.$route.params.tattooer_id
          ? _vm.$router.push({
              name: 'customer_inscription_studio_pre',
              params: {
                token: _vm.$route.params.token,
                back: true,
              },
            })
          : _vm.$emit('back')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",staticStyle:{"height":"30px"},attrs:{"color":"primary","type":"submit","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }